<template>
  <v-container>
    <v-row>
      <v-col class="mb-4">
        <h1 class="text-h6 mb-3 primary--text">¿POR QUÉ BUSCAMOS, ENTONCES, A LAS MEJORES?</h1>

        <p>
          Somos conscientes de que una mujer extraordinaria puede generar desigualdad de forma transitoria, pero también sabemos que su
          capacidad para generar empleo hace posible que nuestro modelo dispare el impacto social generado.
        </p>
        <p>
          <span class="secondary--text">Que sean motores sociales cierra el círculo de impacto social</span> y rentabilidad que buscamos.
        </p>
      </v-col>

      <!-- <v-col cols="12">
        <v-img :src="require('@/assets/images/we_look_for_the_best.svg')" class="my-3" contain height="200" />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "WeLookForTheBest",
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bgmobile-weLookForTheBest.png") + ")"
      });
    } else {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bg-weLookForTheBest.png") + ")"
      });
    }
  }
};
</script>
